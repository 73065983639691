@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'intl-tel-input/build/css/intlTelInput.css';

/*
Breakpoint prefix	Minimum width	CSS
mobile: => '360px' => @media (min-width: 360px)
'sm': '768px', => @media (min-width: 768px)
'md': '1024px', => @media (min-width: 1024px)
'lg': '1280px', => @media (min-width: 1280px)
'xl': '1440px', => @media (min-width: 1440px)
'2xl': '1536px', => @media (min-width: 1536px)
'3xl': '1920px' => @media (min-width: 1920px)

Breakpoint	Screen Width	Typical Device	          Full Screen Height	  Estimated Usable Height (Desktop)
            360px         mobile
sm	        768px	        Tablets in Portrait	      1024px	              904px
md	        1024px	      Tablets in Landscape	    768px	                648px
lg	        1280px	      Laptops	                  800px	                610px
xl	        1440px	      High-resolution Laptops	  900px	                710px
2xl	        1536px	      Larger                    960px	                770px
3xl	        1920px	      Desktop Monitors	        1080px	              890px
*/

html, body {
  font-family: "Poppins", ui-sans-serif, sans-serif;
}

body {
  background-image: url('/assets/images/bg.webp');
  background-position: center; /* Center the background image */
  background-size: cover; /* Cover the entire page */
  //overflow: hidden; /* Prevents scroll */
}

button {
  &.main-button-design {
    @apply
    w-[80%] h-[50px] rounded-[24.7px] text-[15px]
    sm:text-[32.5px] sm:rounded-[53.5px] sm:h-[107px]
    md:text-[29.5px] md:rounded-[39px] md:h-[81px]
    lg:w-[312px] lg:max-w-none lg:h-[55px] lg:rounded-[27px] lg:text-[16px]
    xl:w-[389px] xl:h-[75px] xl:text-[20px] xl:rounded-[37.5px];

    color: #fff;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    white-space: nowrap;

    &:hover {
      transform: translateY(-3px); /* Slightly move up on hover */
    }
  }

  &.gradient-button {
    background-image: linear-gradient(to right, #8a44c8, #df0c39);

    &:hover {
      box-shadow: 0 4px 15px rgba(223, 12, 174, 0.4);
    }
  }

  &.black-button {
    background-color: #252931;

    &:hover {
      box-shadow: 0 4px 15px rgba(50, 50, 93, 0.4);
    }
  }

  &.disabled {
    background-color: #d2d2d2;
    cursor: default;
    pointer-events: none;
  }

  &.subscription-button {
    @apply p-0 w-full xl:text-[12.5px] xl:rounded-[17px] xl:h-[34px] xl:mb-[23px]
    lg:text-[10px] lg:rounded-[13.6px] lg:h-[28px] lg:mb-[18px]
    md:text-[29.5px] md:rounded-[39px] md:h-[81px]
    sm:text-[32.5px] sm:rounded-[53.5px] sm:h-[107px] sm:mb-[50px]
    text-[13.5px] h-[38px] rounded-[18.5px] mb-[25px];
  }
}

.white-container {
  // half the robot size
  @apply
  -mt-[41.5px]
  sm:-mt-[109.5px]
  lg:-mt-[48.5px]
  3xl:-mt-[61px];

  background-color: #fff;
  border-radius: 42px;
  box-shadow: 0 0 59.2px 8.8px rgba(1, 1, 1, 0.06);
  padding-top: 118px;
}

.font-prop-normal {
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
}

.input-style {
  // tracking- - letter-spacing
  @apply xl:text-[16px] xl:tracking-[-0.16px]
  lg:text-[12.5px] lg:h-[60px] lg:tracking-[-0.13px] lg:rounded-[29.7px] lg:px-[26px]
  sm:text-[30px] sm:h-[94px] sm:tracking-[-0.3px] sm:rounded-[46px] sm:px-[57px]
  text-[14px] h-[44px] px-[26px] tracking-[-0.14px] rounded-[21.2px];

  border: solid 1px rgba(37, 41, 49, 0.3);
  font-weight: 300;
  width: 100%;

  ::placeholder {
    color: red;
  }
}

.invalid-input-container {
  @apply absolute text-[#f00] flex items-baseline pl-[26px] mt-[4px];

  .invalid-input {
    font-size: 12.5px;
    font-weight: 300;
    letter-spacing: -0.13px;
    margin-left: 7px;
  }
}

.article {
  &--header {
    @apply xl:text-[38px];
    color: #252931;
    font-weight: 600;
  }

  &--content {
    @apply xl:text-[21.5px];
    @apply xl:text-[38px];
    color: #252931;
  }
}

.article-container {
  @apply flex flex-col items-center;

  .article-content {
    @apply xl:w-[703px] xl:me-[66px]
    lg:w-[650px] lg:ms-0 lg:me-[45px] lg:text-start
    sm:w-[85%]
    w-[90%] mx-auto text-center;

    &__title {
      @apply xl:text-[38px] xl:mb-[56px]
      lg:text-[30px] lg:mb-[36px]
      sm:text-[42px]
      text-[19px] mb-[24px];

      color: #252931;
      font-weight: 600;
    }

    &__subtitle {
      @apply xl:text-[30px] xl:mt-[30px] xl:mb-[15px]
      lg:text-[22px] lg:mt-[22px]
      sm:text-[34px] sm:mt-[20px]
      text-[16px] mt-[18px] mb-[7px];

      color: #252931;
      font-weight: 500;
    }

    &__text {
      @apply xl:text-[21.5px]
      lg:text-[16.5px]
      sm:text-[29.5px]
      text-[14px];

      color: #252931;
      font-stretch: normal;
      font-style: normal;
      font-weight: normal;
      letter-spacing: normal;
      line-height: 1.51;
      text-align: justify;

      p {
        @apply xl:mb-[60px] lg:mb-[40px] mb-[20px];
      }
    }
  }

  .action {
    @apply xl:w-[413px] xl:h-[240px] xl:rounded-[13px] xl:pe-[82px] xl:ps-[32px]
    lg:w-[313px] lg:h-[192px] lg:rounded-[10.4px] lg:pt-[35px] lg:pe-[35px] lg:pb-[43px] lg:ps-[25px] lg:mx-0 lg:mt-0
    sm:w-[85%]
    w-[90%] mx-auto mt-[20px] rounded-[4.8px] pt-[14px] ps-[14px] pe-[8px] pb-[23px];

    background-color: #fff;
    border-radius: 13px;
    box-shadow: 0 0 15.3px 1.7px rgba(37, 41, 49, 0.01);

    &--img {
      @apply relative
      xl:w-[30px] xl:h-[30px]
      lg:w-[24px] lg:h-[24px]
      sm:w-[37px] sm:h-[37px]
      w-[16px] h-[16px];
    }

    &--title {
      @apply font-bold
      xl:text-[20.5px] xl:mt-[17px]
      lg:text-[16.5px] lg:mt-[13px]
      sm:text-[24px] sm:mt-[21px]
      text-[14px] mt-[8px];

      color: #252931;
    }

    &--subtitle {
      @apply xl:mt-[6px]
      lg:text-[14.5px] lg:mt-[4px] lg:leading-[1.48]
      sm:text-[16.5px] sm:mt-[8px] sm:leading-[1.52]
      text-[12px] mt-[2px] leading-[1.53];

      color: #252931;
    }
  }
}

.modal-background {
  @apply fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50;

  .modal-container {
    @apply relative bg-white flex flex-col items-center justify-between py-[36px];

    border-radius: 42px;
    box-shadow: 0 0 59.2px 8.8px rgba(1, 1, 1, 0.06);

    &.error-modal {
      @apply lg:w-[383px] w-[90%] h-[423px];
    }

    &.coupon-modal {
      @apply transition-all w-[90%] max-w-[594px] h-[440px] sm:h-[540px];

      &.subscription-package {
        @apply xl:h-[620px] h-[580px] w-[90%] max-w-[500px];
      }
    }

    .modal-header {
      @apply font-light text-[38px];

      &.coupon-header {
        @apply text-[18px] sm:text-[30px] lg:text-[26px] mb-8;
      }
    }

    .modal-content {
      @apply lg:text-[16px] sm:text-[26px] text-[14px] px-[40px] text-center;
      line-height: 1.69;
    }
  }
}

.subscription-packages {
  .package {
    @apply flex flex-col bg-white mx-2
    xl:h-[562px] xl:w-[312px] xl:px-[16px]
    lg:h-[450px] lg:w-[286px] lg:px-[12px] lg:mb-0 lg:rounded-[26px] lg:max-w-none
    sm:h-[1260px] sm:max-w-[702px] sm:px-[53px] sm:mb-[69px] sm:rounded-[67px]
    h-[640px] w-[90%] max-w-[350px] px-[17px] mb-[31px] rounded-[30.9px];
    box-shadow: 0 0 59.2px 8.8px rgba(1, 1, 1, 0.06);

    &:first-of-type {
      @apply ml-0;
    }

    &:last-of-type {
      @apply mr-0;
    }

    &.selected {
      border: solid 2px #b32983;
      box-shadow: 0 0 59.2px 8.8px rgba(1, 1, 1, 0.06);
    }

    .type {
      @apply flex justify-between
      lg:pt-[20px] lg:pb-[12px]
      sm:pt-[35px] sm:pb-[27px]
      pt-[24px] pb-[16px];

      border-bottom: 2px solid #dadada;

      &__user {
        @apply xl:text-[22px] lg:text-[17px] sm:text-[51px] text-[23.5px];

        color: #1b1e23;
        font-weight: 600;
        letter-spacing: -0.22px;
      }

      &__chosen {
        @apply xl:text-[18px] lg:text-[14px] sm:text-[46px] text-[23px];

        color: #1b1e23;
        font-weight: normal;
        letter-spacing: -0.18px;
      }
    }

    .total {
      @apply flex
      lg:pt-[10px] lg:pb-[6px]
      pt-[14px] pb-[10px];

      border-bottom: 2px solid #dadada;

      &__currency {
        @apply xl:text-[24.5px] lg:text-[19.5px] sm:text-[58px] text-[26.5px];
      }

      &__amount {
        @apply xl:text-[65px]
        lg:text-[52px] lg:me-[4px]
        sm:text-[150px]
        text-[70px] me-[4px];

        font-weight: 600;
        letter-spacing: -0.65px;
        line-height: 1;
      }

      &__interval {
        @apply relative
        xl:text-[20px]
        lg:text-[16px] lg:tracking-[-0.2px]
        sm:text-[47px] sm:tracking-[-0.47px]
        text-[21.5px] tracking-[-0.22px];

        color: #1b1e23;
      }

      &__credits {
        @apply relative xl:text-[20px] xl:tracking-[-0.2px]
        lg:-mt-[4px] lg:text-[16px] lg:tracking-[-0.16px]
        sm:text-[47px] sm:tracking-[-0.47px]
        text-[21.5px] tracking-[-0.22px];

        color: #1b1e23;
        font-weight: bold;
      }

      &__custom {
        @apply font-bold xl:text-[40px] xl:tracking-[-0.4px]
        lg:text-[32px] lg:mb-[4px] lg:tracking-[-0.12px]
        sm:text-[94px] sm:mb-[8px] sm:tracking-[-0.94px]
        text-[43.5px] mb-[4px] tracking-[-0.44px];

        color: #1b1e23;
      }
    }

    .description {
      @apply flex-grow xl:pt-[18px] lg:pt-[16px] pt-[18px];

      ul {
        @apply lg:ps-[21px] ps-[25px];

        list-style-type: disc;

        li {
          @apply
          xl:text-[14px]
          lg:text-[11.5px] lg:leading-[1.75] lg:font-[500]
          sm:text-[33.5px] sm:leading-[1.73]
          text-[15.5px] leading-[1.71] font-[300];

          color: #1b1e23;
        }
      }

      &__custom-text {
        @apply block
        xl:text-[14px]
        lg:text-[11.5px] lg:font-[500] lg:ps-[8px] lg:tracking-[-0.14px] lg:leading-[1.75]
        sm:text-[33.5px] sm:tracking-[-0.34px] sm:leading-[1.73]
        text-[15.5px] font-[300] ps-[6px] tracking-[-0.16px] leading-[1.71];

        color: #1b1e23;
      }
    }
  }
}

.input-style.iti__tel-input {
  font-family: sans-serif;
}

.invalid-input-container {
  @apply absolute text-[#f00] flex items-baseline pl-[26px] mt-[4px];

  .invalid-input {
    font-size: 12.5px;
    font-weight: 300;
    letter-spacing: -0.13px;
    margin-left: 7px;
  }
}

@media screen and (max-width: 767px) {
  .coupon-package {
    margin: -40px auto 0 auto !important;
    transform: scale(.9) !important;
    //width: 110% !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .coupon-package {
    margin: -220px auto 0 -17% !important;
    transform: scale(.6) !important;
    width: 140% !important;
  }
}
